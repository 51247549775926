import { Booking } from '@app/domain/Booking'
import { BookingReason } from '../../../../domain/BookingReason'
import { QuestionType } from '@app/domain/Question'
import { ValidatorFn, Validators } from '@angular/forms'
import { Venue } from '@app/domain/Venue'
import DineroFactory, { Currency } from 'dinero.js'

export interface BookingDetailsViewModelDelegate {

    showChangeBookingEndTimeOptions(): void
    showChangeBookingReasonOptions(): void
    updateBookingTime(time: Date): void
    updateBookingReason(reason: BookingReason | null): void
    updateCustomerMessage(message: string | null): void
    updateMerchantNotes(notes: string | null): void
    updatePartySize(size: string | null): void
    goToCustomer(): void
}

export class BookingDetailsViewModel {

    bookingName: string | null
    bookingSize: number
    bookingNotes: string | null
    bookingReason: string | null
    bookingEvent: string | null
    cancellationCutOffDate: Date | null
    depositAmount: string | null
    depositRefundDate: Date | null
    canAcceptBooking: boolean = false
    canChangeTime!: boolean
    canGoToCustomer: boolean
    checkboxQuestions: { question: string, ticked: boolean }[]
    dropdownQuestions: { question: string, answer: string }[]
    isEditingBooking: boolean = false
    partySizePluralMapping = {
        '=0': 'No People',
        '=1': '1 Person',
        other: '# People',
    }
    partySizeValidators: ValidatorFn [] = [
        Validators.required,
        Validators.min(1),
    ]
    reasonsReservableOnBookingDate: BookingReason[]
    showHidePrivateNotesButton: boolean = false
    showShowPrivateNotesButton: boolean = false
    hidePrivateNotes: boolean
    expiryDate: Date | null

    constructor(
        private venue: Venue,
        private booking: Booking,
        hidePrivateNotesEnabled: boolean,
        private delegate: BookingDetailsViewModelDelegate
    ) {
        this.bookingName = this.makeBookingName()
        this.bookingSize = this.booking.size
        this.bookingNotes = this.booking.notes
        const reason = this.booking.reasonId ? this.venue.reasonWithId(this.booking.reasonId) : null
        this.bookingReason = reason?.displayName ?? null
        const event = this.booking.eventId ? this.venue.eventWithId(this.booking.eventId) : null
        this.bookingEvent = event?.displayName ?? null
        this.cancellationCutOffDate = this.booking.cancellationCutOffDate()
        if (this.booking.deposit) {
            this.depositAmount = DineroFactory({
                amount: this.booking.deposit.amount,
                currency: this.booking.deposit.currencyCode as Currency,
            }).toFormat()
            this.depositRefundDate = this.booking.deposit.dateRefunded
        } else {
            this.depositAmount = null
            this.depositRefundDate = null
        }

        this.canAcceptBooking = booking.status().canAcceptBooking()
        this.canChangeTime = booking.status().canModifyBooking()
        this.canGoToCustomer = booking.customer !== null

        this.checkboxQuestions = this.booking.answers
            .filter(answer => answer.question.isTickable)
            .map(answer => {
                return {
                    question: answer.question.merchantDisplayText,
                    ticked: answer.isTicked(),
                }
            })
        this.dropdownQuestions = this.booking.answers
            .filter(answer => answer.question.type === QuestionType.Dropdown)
            .map(answer => {
                return {
                    question: answer.question.merchantDisplayText,
                    answer: answer.answer,
                }
            })
        this.reasonsReservableOnBookingDate = this.venue.reasonsReservableOnDate(this.booking.start)
        this.hidePrivateNotes = hidePrivateNotesEnabled && booking.merchantNotes !== null
        this.showHidePrivateNotesButton = false
        this.showShowPrivateNotesButton = hidePrivateNotesEnabled && booking.merchantNotes !== null
        this.expiryDate = booking.expiryDate()
    }

    showChangeBookingTimeOptions() {
        this.delegate.showChangeBookingEndTimeOptions()
    }

    showChangeBookingReasonOptions() {
        this.delegate.showChangeBookingReasonOptions()
    }

    updateBookingTime(time: Date) {
        this.delegate.updateBookingTime(time)
    }

    updateBookingReason(reason: BookingReason | null) {
        this.delegate.updateBookingReason(reason)
    }

    updateCustomerMessage(message: string | null) {
        this.delegate.updateCustomerMessage(message)
    }

    updateMerchantNotes(notes: string | null) {
        this.delegate.updateMerchantNotes(notes)
    }

    updatePartySize(size: string | null) {
        this.delegate.updatePartySize(size)
    }

    togglePrivateNotesVisibility() {
        this.hidePrivateNotes = !this.hidePrivateNotes
        this.showHidePrivateNotesButton = !this.showHidePrivateNotesButton
        this.showShowPrivateNotesButton = !this.showShowPrivateNotesButton
    }

    goToCustomer() {
        this.delegate.goToCustomer()
    }

    private makeBookingName() {
        const name = this.booking.name
        if (name.length === 0) {
            return null
        }
        return name
    }
}

import { Area } from '@app/domain/Area'
import { BehaviorSubject, Observable, map, of } from 'rxjs'
import { Booking } from '@app/domain/Booking'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
    FloorTableViewModel,
} from '@app/features/shared/components/area-floor/floor-table.view-model'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Table } from '@app/domain/Table'
import { Venue } from '@app/domain/Venue'
import { activeBookingStatusTypes } from '@app/domain/BookingStatus'

@Component({
    selector: 'app-table-selection',
    templateUrl: './table-selection.component.html',
    styleUrls: ['./table-selection.component.sass'],
})
export class TableSelectionComponent implements OnInit, TableSelectionFloorTableViewModelDelegate {

    @Input() public venue!: Venue
    @Input() public bookings!: Booking[]
    @Input() public initialArea?: Area
    @Input() public currentlySelectedTables!: Table[]
    @Input() public partySize!: number | null
    @Input() public startDate!: Date | null
    @Input() public endDate!: Date | null
    @Input() public isSavingSelection?: Observable<boolean>
    @Input() public canSelectTablesFromMultipleAreas = false
    @Input() public additionalContextTitle?: string
    @Output() public tablesSelected = new EventEmitter<Table[]>()
    selectedArea!: Area
    tableViewModels!: FloorTableViewModel[]
    selectedTables!: BehaviorSubject<Table[]>
    selectedTables$!: Observable<Table[]>
    freeTables$!: Observable<Table[]>
    canUseSelectedTables!: Observable<boolean>
    selectedTableCapacity$!: Observable<number>

    constructor(
        private modal: NgbActiveModal
    ) { }

    ngOnInit() {
        this.selectInitialArea()
        this.selectedTables = new BehaviorSubject(this.currentlySelectedTables)
        this.selectedTables$ = this.selectedTables.asObservable()
        this.tableViewModels = this.makeTableViewModels()
        this.canUseSelectedTables = this.selectedTables$.pipe(
            map(tables => tables.length > 0)
        )
        this.selectedTableCapacity$ = this.selectedTables$.pipe(
            map(tables => {
                return tables.reduce((acc, table) => acc + table.maximumSeats, 0)
            })
        )
        this.bindFreeTablesToTablesNotDisabledOrSelected()
    }

    cancelClicked() {
        this.modal.dismiss()
    }

    saveClicked() {
        this.tablesSelected.emit(this.selectedTables.getValue())
    }

    removeTableClicked(table: Table) {
        const selectedTables = this.selectedTables.getValue()
        const index = selectedTables.findIndex(t => t.id === table.id)
        if (index === -1) {
            return
        }
        selectedTables.splice(index, 1)
        this.selectedTables.next(selectedTables)
        this.tableViewModels = this.makeTableViewModels()
    }

    clearTablesClicked() {
        this.selectedTables.next([])
        this.tableViewModels = this.makeTableViewModels()
    }

    areaSelected(area: Area) {
        this.selectedArea = area
        this.tableViewModels = this.makeTableViewModels()
    }

    selectAllInArea() {
        const areaTables = this.selectedArea.tables
        this.selectedTables.next(areaTables)
    }

    tableClicked(table: Table) {
        const selectedTables = this.selectedTables.getValue()
        if (selectedTables.find(t => t.id === table.id)) {
            this.selectedTables.next(selectedTables.filter(t => t.id !== table.id))
            this.tableViewModels = this.makeTableViewModels()
            return
        }
        this.selectedTables.next([...selectedTables, table])
        this.tableViewModels = this.makeTableViewModels()
    }

    tableIdSelected(freeTables: Table[], target: EventTarget) {
        const selectTarget = target as HTMLSelectElement
        const tableId = selectTarget.value
        if (!tableId) {
            return
        }
        const table = freeTables.find(t => t.id === tableId)
        if (!table) {
            return
        }
        this.tableClicked(table)
    }

    isTableDisabled(table: Table): boolean {
        const activeBookingsCoveringSameTime = this.bookings
            .filter(booking => {
                return activeBookingStatusTypes().includes(booking.status().type)
            })
            .filter(booking => {
                if (this.startDate === null || this.endDate === null) {
                    return false
                }
                return booking.occupiesTableBetweenDates(this.startDate, this.endDate, [table])
            })
        const isBooked = activeBookingsCoveringSameTime.length > 0
        const selectedTables = this.selectedTables.getValue()
        const wasBookedBeforeSelectionStarted = this.currentlySelectedTables
            .find(t => t.id === table.id) !== undefined 
            && selectedTables.find(t => t.id === table.id) !== undefined
        if (isBooked && !wasBookedBeforeSelectionStarted) {
            return true
        }
        if (this.canSelectTablesFromMultipleAreas) {
            return false
        }
        const selectedArea = this.venue.areas.map(area => {
            const hasSelectedTable = area.tables.find(t => {
                return selectedTables.find(st => st.id === t.id)
            })
            if (hasSelectedTable) {
                return area
            }
            return null
        }).find(area => area !== null)
        const tableNotInSelectedArea = selectedArea
            && selectedArea.tables.find(t => t.id === table.id) === undefined
        if (tableNotInSelectedArea) {
            return true
        }
        return false
    }

    private makeTableViewModels(): FloorTableViewModel[] {
        return this.tableViewModels = this.selectedArea.tables.map(table => {
            const isSelected = !!this.selectedTables.getValue().find(t => t.id === table.id)
            return new TableSelectionFloorTableViewModel(
                table,
                this.isTableDisabled(table),
                isSelected,
                this
            )
        })
    }

    private bindFreeTablesToTablesNotDisabledOrSelected() {
        this.freeTables$ = this.selectedTables$.pipe(
            map(selectedTables => {
                const allTables = this.venue.areas.reduce((acc, area) => {
                    return acc.concat(area.tables)
                }, [] as Table[])
                return [selectedTables, allTables] as [Table[], Table[]]
            }),
            map(([selectedTables, tables]) => {
                return tables.filter(table => {
                    if (this.isTableDisabled(table)) {
                        return false
                    }
                    if (selectedTables.find(t => t.id === table.id)) {
                        return false
                    }
                    return true
                })
            })
        )
    }

    private selectInitialArea() {
        if (this.initialArea) {
            this.selectedArea = this.initialArea
        } else if (this.currentlySelectedTables.length > 0) {
            const table = this.currentlySelectedTables[0]
            this.selectedArea = this.venue.areaWithTableId(table.id)!
        } else {
            this.selectedArea = this.venue.areas[0]
        }
    }
}

export interface TableSelectionFloorTableViewModelDelegate {
    tableClicked(table: Table): void
}


export class TableSelectionFloorTableViewModel implements FloorTableViewModel {

    public dimensions: { width: number, height: number }
    public coordinates: { x: number, y: number }
    public displayName: string
    public canUpdateCoordinates: boolean = false

    coordinates$: Observable<{ x: number, y: number }>

    constructor(
        public table: Table,
        public isDisabled: boolean,
        public isSelected: boolean,
        private delegate: TableSelectionFloorTableViewModelDelegate
    ) {
        this.coordinates = table.floorCoordinates
        this.coordinates$ = of(this.coordinates)
        this.dimensions = table.dimensions
        this.displayName = table.shortFormattedDisplayName
        this.isDisabled = isDisabled
        this.isSelected = isSelected
    }

    get isBooked(): boolean {
        return false
    }

    get isSeated(): boolean {
        return false
    }

    startInteraction() {
        // Do nothing
    }

    endInteraction() {
        // Do nothing
    }

    updateCoordinates(coordinates: { x: number, y: number }) {
        // Do nothing
    }

    updateDimensions(dimensions: { width: number, height: number }) {
        // Do nothing
    }

    tableClicked() {
        if (this.isDisabled) {
            return
        }
        this.delegate.tableClicked(this.table)
    }

    hasMovedOrResized(): boolean {
        return false
    }
}


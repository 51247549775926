<form
    [formGroup]="form"
>
    <div class="vstack gap-3">
        <div class="vstack gap-1">
            <label
                for="amount"
                class="form-label m-0 fw-semibold"
            >
                Amount
            </label>
            <div class="vstack gap-1">
                <div class="hstack gap-2">
                    <div class="input-group">
                        <span class="input-group-text">£</span>
                        <input
                            type="number"
                            class="form-control"
                            formControlName="amount"
                            placeholder="Amount"
                        />
                        <span class="input-group-text">.00</span>
                    </div>
                    <div
                        class="d-flex btn-group segmented-control justify-content-evenly"
                        role="group"
                    >
                        <input
                            type="radio"
                            class="btn-check"
                            id="perCover"
                            autocomplete="off"
                            [value]="true"
                            formControlName="isPerCover"
                        >
                        <label
                            class="btn btn-outline-primary text-nowrap"
                            for="perCover"
                            (click)="form.get('isPerCover')?.setValue(true)"
                        >
                            Per Cover
                        </label>
                        <input
                            type="radio"
                            class="btn-check"
                            id="perParty"
                            autocomplete="off"
                            [value]="false"
                            formControlName="isPerCover"
                        >
                        <label
                            class="btn btn-outline-primary text-nowrap"
                            for="perParty"
                            (click)="form.get('isPerCover')?.setValue(false)"
                        >
                            Flat Rate
                        </label>
                    </div>
                </div>
                <span class="form-text">
                    @if (this.form.get('chargeType')?.value === 'cancellationCharge') {
                        The amount the customer could be charged
                            if you decide to charge them for cancelling / not showing up.
                    } @else if (this.form.get('chargeType')?.value === 'depositCharge') {
                        The amount to charge for securing the booking.
                    }
                </span>
            </div>
        </div>
        @if (form.get('linkDeliveryMethod') !== null) {
            <div class="vstack gap-2">
                <label
                    class="form-label m-0 fw-semibold"
                >
                    Send to:
                </label>
                <div class="vstack gap-2 align-content-stretch">
                    <label
                        class="btn btn-outline-secondary p-2 d-flex flex-column justify-content-center align-items-center"
                        [class.disabled]="booking.emailAddress === null"
                    >
                        <div class="m-1 hstack gap-2">
                            <input
                                type="radio"
                                [value]="'email'"
                                formControlName="linkDeliveryMethod"
                                class="form-check-input m-0"
                                role="button"
                            >
                            <div class="vstack align-items-start">
                            <span
                                *ngIf="booking.emailAddress !== null"
                                class="fw-semibold"
                            >
                                Email: {{ booking.emailAddress }}
                            </span>
                                <span
                                    *ngIf="booking.emailAddress === null"
                                    class="fw-normal"
                                >
                                Email: Email address not provided
                            </span>
                            </div>
                        </div>
                    </label>
                    <label
                        class="btn btn-outline-secondary p-2 d-flex flex-column justify-content-center align-items-center"
                        [class.disabled]="booking.phoneNumber === null"
                    >
                        <div class="m-1 hstack gap-2">
                            <input
                                type="radio"
                                [value]="'sms'"
                                formControlName="linkDeliveryMethod"
                                class="form-check-input m-0"
                                role="button"
                            >
                            <div class="vstack align-items-start">
                            <span
                                *ngIf="booking.phoneNumber !== null"
                                class="fw-semibold"
                            >
                                SMS: {{ booking.phoneNumber | phoneNumber }}
                            </span>
                                <span
                                    *ngIf="booking.phoneNumber === null"
                                    class="fw-normal"
                                >
                                SMS: Phone number not provided
                            </span>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        }
        @if (form.get('expiryMinutes') !== null) {
            <div class="vstack gap-2">
                <label
                    for="expiryMinutes"
                    class="form-label m-0 fw-semibold"
                >
                    Expiry Time
                </label>
                <select
                    class="form-select"
                    formControlName="expiryMinutes"
                >
                    @if (previousExpiryDate) {
                        <option
                            [value]="-1"
                        >
                            Don't Change - Expires at {{ previousExpiryDate | date: 'short' }}
                        </option>
                    }
                    @for (minutes of addPaymentExpiryOptions; track minutes) {
                        @if (minutes === null) {
                            <option
                                [value]="null"
                            >
                                Never Expires
                            </option>
                        } @else {
                            <option
                                [value]="minutes"
                            >
                                Expires {{ minutes | duration : DurationUnit.Minute | titlecase }}
                                from now
                            </option>
                        }
                    }
                </select>
                <span class="form-text">
                        How long the link will be valid for before it expires and the booking is cancelled.
                    </span>
            </div>
        }
    </div>
</form>

import { AddChargeComponent } from '../add-charge/add-charge.component'
import { Booking } from '@app/domain/Booking'
import { BookingChangedMetadata } from '@app/features/shared/components/booking/booking.component'
import { BookingService } from '@services/booking.service'
import { BookingStatusType } from '@app/domain/BookingStatus'
import { Business } from '@app/domain/Business'
import {
    Component,
    EventEmitter,
    Input, OnChanges, OnDestroy,
    Output, SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core'
import { ContextService } from '@services/context.service'
import { DiaryPreferencesService } from '@app/features/bookings/services/diary-preferences.service'
import { DurationUnit } from '../../pipes/duration.pipe'
import { EventService } from '@services/event.service'
import { FormBuilder } from '@angular/forms'
import { ModalComponent } from '@app/features/shared/components/modal/modal.component'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { PaymentsService } from '@services/payments.service'
import { ToastBuilder } from '@app/domain/ToastBuilder'
import { ToastService } from '@services/toast.service'
import { ToastType } from '@app/domain/Toast'
import { Venue } from '@app/domain/Venue'
import { finalize, mergeMap, takeUntil, tap } from 'rxjs'

@Component({
    selector: 'app-booking-payments',
    templateUrl: './booking-payments.component.html',
})
export class BookingPaymentsComponent implements OnChanges, OnDestroy {

    @Input() business!: Business
    @Input() venue!: Venue
    @Input() booking!: Booking
    @Output() bookingChanged = new EventEmitter<[Booking, Partial<BookingChangedMetadata>]>()
    @ViewChild('waivePendingPaymentModal') waivePendingPaymentModal!: TemplateRef<any>
    noPaymentsInvolved = false
    isEditingBooking = false
    private onDestroy$ = new EventEmitter<void>()

    constructor(
        private contextService: ContextService,
        protected diaryPreferencesService: DiaryPreferencesService,
        private bookingService: BookingService,
        private eventService: EventService,
        private fb: FormBuilder,
        private paymentService: PaymentsService,
        private toastService: ToastService,
        private modal: NgbActiveModal,
        private modalService: NgbModal
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['booking']) {
            this.noPaymentsInvolved = this.booking.pendingCancellationCharge === null
            && this.booking.pendingDepositCharge === null
            && this.booking.cancellation === null
            && this.booking.deposit === null
        }
    }

    ngOnDestroy() {
        this.onDestroy$.emit()
        this.onDestroy$.complete()
    }

    addCancellationCharge() {
        this.addCharge('cancellationCharge')
    }

    addDepositCharge() {
        this.addCharge('depositCharge')
    }

    editPendingPayment() {
        const modal = this.modalService.open(AddChargeComponent)
        const component = modal.componentInstance as AddChargeComponent
        component.business = this.business
        component.venue = this.venue
        component.booking = this.booking
        component.bookingChanged
            .pipe(
                takeUntil(this.onDestroy$),
                tap(() => modal.close())
            )
            .subscribe({
                next: (booking) => {
                    this.toastService.show(
                        new ToastBuilder('Charge Updated')
                            .withType(ToastType.Success)
                            .withHeader('Edit Charge')
                            .build()
                    )
                    this.bookingChanged.emit([booking, { changeHasFinishedInteraction: false }])
                },
                error: _ => {
                    //TODO Track the error
                    this.showToast(
                        ToastType.Danger,
                        'Couldn\'t edit pending payment. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    waivePendingPayment() {
        const modal = this.modalService.open(ModalComponent)
        const component = modal.componentInstance as ModalComponent
        component.title = 'Confirm'
        component.actionType = 'confirm'
        component.actionButtonTitle = 'Waive'
        component.cancelButtonTitle = 'Cancel'
        component.contentTemplate = this.waivePendingPaymentModal
        component.actionSelected
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => {
                    modal.close()
                    this.unseatBooking()
                },
            })
    }

    chargeCancellationFeeClicked(confirmContentTemplate: TemplateRef<any>) {
        const user = this.contextService.getUser()
        if (!user) {
            return
        }
        const area  = this.venue.areaBookingIsIn(this.booking)
        if (!area) {
            return
        }
        const modal = this.modalService.open(ModalComponent)
        const component = modal.componentInstance as ModalComponent
        component.title = 'Charge Cancellation Fee'
        component.actionType = 'confirm'
        component.contentTemplate = confirmContentTemplate
        component.actionButtonTitle = 'Charge'
        component.cancelButtonTitle = 'Cancel'
        component.cancelPerformsAction = false
        component.actionSelected
            .pipe(
                takeUntil(this.onDestroy$),
                tap(() => component.isPerformingAction = true),
                finalize(() => component.isPerformingAction = false),
                mergeMap(() => {
                    return this.bookingService.chargeCancellationFee(
                        this.business.id,
                        this.venue.id,
                        area.id,
                        this.booking.id,
                        null
                    )
                })
            )
            .subscribe({
                next: booking => {
                    modal.close()
                    this.bookingChanged.emit([booking, { changeHasFinishedInteraction: false }])
                },
                error: _ =>{
                    //TODO Track the error
                    this.showToast(
                        ToastType.Danger,
                        'Couldn\'t charge customer. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
        component.cancelSelected
            .pipe(
                takeUntil(this.onDestroy$),
                tap(() => component.isPerformingAction = true),
                finalize(() => component.isPerformingAction = false),
                mergeMap(() => {
                    return this.bookingService.chargeCancellationFee(
                        this.business.id,
                        this.venue.id,
                        area.id,
                        this.booking.id,
                        null
                    )
                })
            )
            .subscribe({
                next: booking => {
                    modal.close()
                    this.bookingChanged.emit([booking, { changeHasFinishedInteraction: false }])
                },
                error: _ =>{
                    //TODO Track the error
                    this.showToast(
                        ToastType.Danger,
                        'Couldn\'t charge customer. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    private unseatBooking() {
        const area = this.venue.areaBookingIsIn(this.booking)
        if (!area) {
            return
        }
        this.isEditingBooking = true
        this.bookingService.unseatBooking(
            this.business.id,
            this.venue.id,
            area.id,
            this.booking.id
        )
            .pipe(
                takeUntil(this.onDestroy$),
                finalize(() => this.isEditingBooking = false)
            )
            .subscribe(booking => {
                this.bookingChanged.emit([booking, {}])
            })
    }

    private addCharge(chargeType: 'cancellationCharge' | 'depositCharge') {
        const modal = this.modalService.open(AddChargeComponent)
        const component = modal.componentInstance as AddChargeComponent
        component.chargeType = chargeType
        component.business = this.business
        component.venue = this.venue
        component.booking = this.booking
        component.bookingChanged
            .pipe(
                takeUntil(this.onDestroy$),
                tap(() => modal.close())
            )
            .subscribe({
                next: (booking) => {
                    this.bookingChanged.emit([booking, { changeHasFinishedInteraction: false }])
                },
                error: _ => {
                    //TODO Track the error
                    this.showToast(
                        ToastType.Danger,
                        'Couldn\'t add cancellation charge. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    private showToast(toastType: ToastType, message: string, title?: string) {
        let  toastBuilder = new ToastBuilder(message)
            .withType(toastType)
        if (title){
            toastBuilder = toastBuilder.withHeader(title)
        }
        this.toastService.show(toastBuilder.build())
    }

    protected readonly BookingStatusType = BookingStatusType
    protected readonly DurationUnit = DurationUnit
}

<div class="modal-header d-flex justify-content-between">
    <h4 class="mb-0 hstack gap-2">
        New Customer
    </h4>
    <button
        type="button"
        class="btn btn-close"
        (click)="dismiss()"
    >
    </button>
</div>
<div
    class="modal-body"
    [formGroup]="form"
>
    <div class="vstack gap-2">
        <div class="vstack gap-2">
            <label
                class="mb-0 form-label fw-semibold"
            >
                First Name
            </label>
            <input
                [formControlName]="'firstName'"
                type="text"
                class="form-control"
                id="firstName"
                placeholder="First Name"
                autocomplete="off"
            >
        </div>
        <div class="vstack gap-2">
            <label
                class="mb-0 form-label fw-semibold"
            >
                Last Name
            </label>
            <input
                [formControlName]="'lastName'"
                type="text"
                class="form-control"
                id="lastName"
                placeholder="Last Name"
                autocomplete="off"
            >
        </div>
        <div class="vstack gap-2">
            <label
                class="mb-0 form-label fw-semibold"
            >
                Email Address
            </label>
            <input
                [formControlName]="'emailAddress'"
                type="text"
                class="form-control"
                id="emailAddress"
                placeholder="Email Address"
                autocomplete="off"
            >
        </div>
        <div class="vstack gap-2">
            <label
                class="mb-0 form-label fw-semibold"
            >
                Phone Number
            </label>
            <input
                [formControlName]="'phoneNumber'"
                type="text"
                class="form-control"
                id="phoneNumber"
                placeholder="Phone Number"
                autocomplete="off"
        >
        </div>
    </div>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="dismiss()"
    >
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-primary"
        [disabled]="form.invalid"
        (click)="addCustomer()"
    >
        Add Customer
    </button>
</div>

import { Booking } from '../../../../domain/Booking'
import { BookingService } from '../../../../services/booking.service'
import { Charge } from '../../../../domain/Charge'
import { Component, EventEmitter, Input, OnInit } from '@angular/core'
import { ContextService } from '../../../../services/context.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastBuilder } from '../../../../domain/ToastBuilder'
import { ToastService } from '../../../../services/toast.service'
import { ToastType } from '../../../../domain/Toast'
import { Venue } from '../../../../domain/Venue'
import { finalize, takeUntil } from 'rxjs'
import DineroFactory from 'dinero.js'

@Component({
    selector: 'app-confirm-undo-cancellation',
    templateUrl: './confirm-undo-cancellation.component.html',
})
export class ConfirmUndoCancellationComponent implements OnInit {

    @Input() booking!: Booking
    @Input() venue!: Venue
    isSaving = false
    expiredCancellationCharge: Charge | null = null
    expiredDepositCharge: Charge | null = null
    expiredDate: Date | null = null
    charge: Charge | null = null
    expiryDate: Date | null = null
    chargeForm: FormGroup | null = null
    form!: FormGroup
    customiseChargeShown = false
    private onDestroy$ = new EventEmitter<void>()

    constructor(
        private bookingService: BookingService,
        private contextService: ContextService,
        private fb: FormBuilder,
        private modal: NgbActiveModal,
        private toastService: ToastService
    ) { }

    ngOnInit() {
        this.expiredCancellationCharge = this.booking.expiredCancellationCharge()
        this.expiredDepositCharge = this.booking.expiredDepositCharge()
        this.form = this.fb.group({
            renewCancellationCharge: [this.expiredCancellationCharge !== null, Validators.required],
            renewDepositCharge: [this.expiredDepositCharge !== null, Validators.required],
        })
        this.expiredDate = this.booking.expiredDate()
        if (this.expiredCancellationCharge) {
            this.showCancellationChargeConfiguration()
        } else if (this.expiredDepositCharge) {
            this.showDepositChargeConfiguration()
        }
        this.bindEnablingCancellationChargeToCreatingChargeForm()
        this.bindEnablingDepositChargeToCreatingChargeForm()
    }

    showCancellationChargeConfiguration() {
        const charge = this.expiredCancellationCharge
        if (!charge) {
            return
        }
        const defaultExpiryMinutes = this.venue.diaryPreferences.pendingPaymentExpiryMinutes
        const money = DineroFactory({
            amount: charge.unitAmount,
            currency: 'GBP',
        })
        this.chargeForm = this.fb.group({
            isPerCover: [charge.isPerCover, Validators.required],
            amount: [money.toRoundedUnit(1), Validators.required],
            expiryMinutes: [defaultExpiryMinutes, Validators.required],
            chargeType: ['cancellationCharge', Validators.required],
        })
    }

    showDepositChargeConfiguration() {
        const charge = this.expiredDepositCharge
        if (!charge) {
            return
        }
        const defaultExpiryMinutes = this.venue.diaryPreferences.pendingPaymentExpiryMinutes
        const money = DineroFactory({
            amount: charge.unitAmount,
            currency: 'GBP',
        })
        this.chargeForm = this.fb.group({
            isPerCover: [charge.isPerCover, Validators.required],
            amount: [money.toRoundedUnit(1), Validators.required],
            expiryMinutes: [defaultExpiryMinutes, Validators.required],
            chargeType: ['depositCharge', Validators.required],
        })
    }

    dismiss() {
        this.modal.dismiss()
    }

    save() {
        const organisation = this.contextService.getOrganisation()
        if (!organisation) {
            return
        }
        const business = this.contextService.getBusiness()
        if (!business) {
            return
        }
        const area = this.venue.areaBookingIsIn(this.booking)
        if (!area) {
            return
        }
        if (this.charge) {
            this.isSaving = true
            this.bookingService.updateCharge(
                organisation,
                business,
                this.venue,
                this.booking,
                this.charge,
                this.expiryDate
            )
                .pipe(
                    takeUntil(this.onDestroy$),
                    finalize(() => this.isSaving = false)
                )
                .subscribe({
                    next: (booking) => {
                        this.modal.close(booking)
                    },
                    error: (_) => {
                        // TODO Track the error
                        this.toastService.show(
                            new ToastBuilder('Couldn\'t update charge. Please try again.')
                                .withType(ToastType.Success)
                                .withHeader('Booking Update Error')
                                .build()
                        )
                    },
                })
        } else {
            this.isSaving = true
            this.bookingService.unseatBooking(
                business.id,
                this.venue.id,
                area.id,
                this.booking.id
            )
                .pipe(
                    takeUntil(this.onDestroy$),
                    finalize(() => this.isSaving = false)
                )
                .subscribe({
                    next: (booking) => {
                        this.modal.close(booking)
                    },
                    error: (_) => {
                        // TODO Track the error
                        this.toastService.show(
                            new ToastBuilder('Couldn\'t undo cancellation. Please try again.')
                                .withType(ToastType.Danger)
                                .withHeader('Booking Update Error')
                                .build()
                        )
                    },
                })
        }
    }

    private bindEnablingCancellationChargeToCreatingChargeForm() {
        this.form.get('renewCancellationCharge')?.valueChanges
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe((renewCancellationCharge) => {
                if (renewCancellationCharge) {
                    this.showCancellationChargeConfiguration()
                } else {
                    this.chargeForm = null
                }
            })
    }

    private bindEnablingDepositChargeToCreatingChargeForm() {
        this.form.get('renewDepositCharge')?.valueChanges
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe((renewDepositCharge) => {
                if (renewDepositCharge) {
                    this.showDepositChargeConfiguration()
                } else {
                    this.chargeForm = null
                }
            })
    }
}

import { Booking } from '../../../../domain/Booking'
import { Charge } from '../../../../domain/Charge'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { DurationUnit } from '../../pipes/duration.pipe'
import { FormGroup } from '@angular/forms'
import { map, startWith, takeUntil } from 'rxjs'

@Component({
    selector: 'app-edit-charge-form',
    templateUrl: './edit-charge-form.component.html',
})
export class EditChargeFormComponent implements OnInit, OnDestroy {

    @Input() form!: FormGroup
    @Input() booking!: Booking
    @Input() previousExpiryDate!: Date | null
    @Output() chargeChange = new EventEmitter<Charge | null>()
    @Output() expiryDateChange = new EventEmitter<Date | null>()
    charge!: Charge | null
    expiryDate!: Date | null
    addPaymentExpiryOptions = [
        null,
        1 * 60,
        2 * 60,
        3 * 60,
        4 * 60,
        5 * 60,
        12 * 60,
        1 * 24 * 60,
        2 * 24 * 60,
        3 * 24 * 60,
        4 * 24 * 60,
        5 * 24 * 60,
        6 * 24 * 60,
        7 * 24 * 60,
    ]
    private onDestroy$ = new EventEmitter<void>()
    protected readonly DurationUnit = DurationUnit

    ngOnInit() {
        this.bindFormToCharge()
        this.bindExpiryMinutesToExpiryDate()
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    private bindFormToCharge() {
        this.form.valueChanges
            .pipe(
                startWith(this.form.value),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => {
                const amount = this.form.get('amount')?.value
                if (amount === null) {
                    this.charge = null
                    return
                }
                const unitAmount = Math.round(amount * 100)
                const isPerCover = this.form.get('isPerCover')?.value
                this.charge = new Charge(
                    this.booking.size,
                    null,
                    null,
                    unitAmount,
                    isPerCover
                )
                this.chargeChange.emit(this.charge)
            })
    }

    private bindExpiryMinutesToExpiryDate() {
        this.form.get('expiryMinutes')?.valueChanges
            .pipe(
                startWith(this.form.get('expiryMinutes')?.value),
                takeUntil(this.onDestroy$),
                map((expiryMinutes) => {
                    if (expiryMinutes === null || expiryMinutes === 'null') {
                        return null
                    }
                    if (expiryMinutes === -1 || expiryMinutes === '-1') {
                        return this.previousExpiryDate
                    }
                    const expiryDate = new Date()
                    const expiryMilliseconds = expiryMinutes * 60 * 1000
                    expiryDate.setTime(expiryDate.getTime() + expiryMilliseconds)
                    return expiryDate
                })
            )
            .subscribe((expiryDate) => {
                if (expiryDate === null) {
                    this.expiryDate = null
                    return
                }
                this.expiryDate = new Date(expiryDate)
                this.expiryDateChange.emit(this.expiryDate)
            })
    }
}

<div
    class="vstack"
>
    <div
        class="bg-body"
    >
        <ul class="nav nav-pills px-3 px-md-4 tabbed position-relative">
            <li
                *ngFor="let area of areas"
                class="nav-item"
            >
                <button
                    class="nav-link"
                    [ngClass]="area === selectedArea ? 'active' : ''"
                    (click)="selectArea(area)"
                >
                    {{ area.displayName }}
                </button>
            </li>
            <div class="position-absolute end-0 top-0 bottom-0 pe-3 vstack justify-content-center">
                <button
                    class="btn btn-outline-secondary btn-sm"
                    [disabled]="!canSelectAllInSelectedArea()"
                    (click)="selectAllInArea()"
                >
                    Select All in {{ selectedArea.displayName | slice: 0 : 15 }}
                </button>
            </div>
        </ul>
        <hr class="m-0"/>
    </div>
    <div
        class="px-3 my-1 py-2 vstack gap-2"
    >
        <h4
            *ngIf="showTime"
            class="card-subtitle text-muted text-center"
        >
            {{ now | date: 'shortTime' }}
        </h4>
        <div class="d-flex flex-column flex-lg-row">
            <app-floor-plan
                class="flex-grow-1"
                [tableViewModels]="viewModels"
            >
            </app-floor-plan>
            <ng-content></ng-content>
        </div>
    </div>
</div>

<app-spinner-overlay
    [showSpinner]="isSaving"
    xmlns="http://www.w3.org/1999/html">
</app-spinner-overlay>
<div class="modal-header">
    <h4 class="mb-0">
        Undo Cancellation
    </h4>
    <button
        type="button"
        class="btn btn-close"
        (click)="dismiss()"
    >
    </button>
</div>
<div
    class="modal-body vstack gap-3"
>
    @if (form) {
        <div
            [formGroup]="form"
            class="vstack gap-3"
        >
            @if (expiredCancellationCharge !== null) {
                <div class="bg-light rounded-3 p-3">
                    <div class="vstack gap-2">
                        <div class="hstack gap-2 justify-content-between">
                            <h6 class="fw-semibold my-0 text-muted">
                                Cancellation Charge
                            </h6>
                            <div class="hstack gap-1">
                                <img
                                    ngSrc="assets/credit-card/normal.svg"
                                    alt="Card"
                                    height="16"
                                    width="16"
                                />
                                <span class="text-muted">
                                    Expired
                                </span>
                            </div>
                        </div>
                        <div class="hstack gap-2 justify-content-between">
                            <div class="vstack gap-2">
                                <span class="fw-semibold fs-6">
                                    {{ null | currencyUnit : expiredCancellationCharge.amount : 'GBP' }}
                                    @if (expiredCancellationCharge.isPerCover) {
                                        ({{ null | currencyUnit : expiredCancellationCharge.unitAmount : 'GBP' }}
                                        x
                                        {{ booking.size }})
                                    }
                                </span>
                            </div>
                        </div>
                        <hr class="my-1" />
                        <div class="vstack">
                            <div class="hstack gap-1">
                                Customer did not enter card details before the link expired at {{ expiredDate | date: 'short' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-1 vstack gap-2">
                    <label
                        for="renewCancellationCharge"
                        class="form-label m-0 fw-semibold"
                    >
                        Renew Cancellation Charge
                    </label>
                    <div class="form-switch hstack gap-3">
                        <label class="form-label" for="renewCancellationCharge">
                            If checked, the customer will be asked to enter card details again before the booking is confirmed.
                        </label>
                        <input
                            class="form-check-input ms-auto"
                            type="checkbox"
                            role="switch"
                            id="renewCancellationCharge"
                            formControlName="renewCancellationCharge"
                        >
                    </div>
                </div>
            } @else if (expiredDepositCharge !== null) {
                <div class="bg-light rounded-3 p-3">
                    <div class="vstack gap-2">
                        <div class="hstack gap-2 justify-content-between">
                            <h6 class="fw-semibold my-0 text-muted">
                                Deposit Charge
                            </h6>
                            <div class="hstack gap-1">
                                <img
                                    ngSrc="assets/cash/normal_dark.svg"
                                    alt="Cash"
                                    height="16"
                                    width="16"
                                />
                                <span class="text-muted">
                                    Expired
                                </span>
                            </div>
                        </div>
                        <div class="hstack gap-2 justify-content-between">
                            <div class="vstack gap-2">
                                <span class="fw-semibold fs-6">
                                    {{ null | currencyUnit : expiredDepositCharge.amount : 'GBP' }}
                                    @if (expiredDepositCharge.isPerCover) {
                                        ({{ null | currencyUnit : expiredDepositCharge.unitAmount : 'GBP' }}
                                        x
                                        {{ booking.size }})
                                    }
                                </span>
                            </div>
                        </div>
                        <hr class="my-1" />
                        <div class="vstack">
                            <div class="hstack gap-1">
                                Customer did not pay their deposit before the link expired at {{ expiredDate | date: 'short' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-1 vstack gap-2">
                    <label
                        for="renewDepositCharge"
                        class="form-label m-0 fw-semibold"
                    >
                        Renew Deposit Charge
                    </label>
                    <div class="form-switch hstack gap-3">
                        <label class="form-label" for="renewDepositCharge">
                            If checked, the customer will be asked to pay the deposit again before the booking is confirmed.
                        </label>
                        <input
                            class="form-check-input ms-auto"
                            type="checkbox"
                            role="switch"
                            id="renewDepositCharge"
                            formControlName="renewDepositCharge"
                        >
                    </div>
                </div>
            } @else {
                <div class="px-1 vstack gap-2">
                    <span class="fw-semibold">
                        Are you sure you want to undo the cancellation of this booking?
                    </span>
                    <span>
                        If there is an email address or phone number associated with the booking, the customer will receive notice that the booking has been reinstated.
                    </span>
                </div>
            }
        </div>
    }
    @if (chargeForm) {
        <div
            ngbAccordion
            (hide)="customiseChargeShown = false"
            (show)="customiseChargeShown = true"
        >
            <div ngbAccordionItem>
                <div
                    ngbAccordionHeader
                    class="accordion-button hide-toggle justify-content-between"
                >
                    <span class="fw-semibold text-body fs-6">
                        @if (charge !== null) {
                            {{ null | currencyUnit : charge.amount : 'GBP' }}
                            @if (charge.isPerCover) {
                                ({{ null | currencyUnit : charge.unitAmount : 'GBP' }}
                                x
                                {{ booking.size }})
                            }
                        } @else {
                            No Charge
                        }
                    </span>
                    <button ngbAccordionToggle class="btn btn-outline-secondary">
                        @if (customiseChargeShown) {
                            Close
                        } @else {
                            Customise
                        }
                    </button>
                </div>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody class="p-3">
                        <app-edit-charge-form
                            [form]="chargeForm"
                            [booking]="booking"
                            [previousExpiryDate]="null"
                            (chargeChange)="charge = $event"
                            (expiryDateChange)="expiryDate = $event"
                        >
                        </app-edit-charge-form>
                    </div>
                </div>
            </div>
        </div>
    }
</div>
<div
    class="modal-footer"
>
    <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="dismiss()"
    >
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-primary"
        (click)="save()"
    >
        Undo Cancellation
    </button>
</div>

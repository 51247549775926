import { AreaDTO } from '@services/DTO/AreaDTO'

export class BookingSlotDTO {

    constructor(
        public dateTime: Date
    ) { }
}

export class AvailabilityDTO {
    constructor(
        public areas: AreaAvailabilityDTO[]
    ) { }
}

export class AreaAvailabilityDTO {
    constructor(
        public area: AreaDTO,
        public bookingSlots: BookingSlotAvailabilityDTO[]
    ) { }
}

export class BookingSlotAvailabilityDTO {
    constructor(
        public bookingSlot: BookingSlotDTO,
        public options: BookingSlotOptionDTO[]
    ) { }
}

export class BookingSlotOptionDTO {
    constructor(
        public partySize: number,
        public allTableCount: number,
        public reservableTableCount: number,
        public reservableReasonIds: string[]
    ) { }
}

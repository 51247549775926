<app-spinner-overlay
    [showSpinner]="isAddingCharge"
    xmlns="http://www.w3.org/1999/html">
</app-spinner-overlay>
<div class="modal-header">
    <h4 class="mb-0">
        @if (chargeType === 'cancellationCharge') {
            Add Cancellation Charge
        } @else if (chargeType === 'depositCharge') {
            Add Deposit Charge
        } @else if (booking.pendingCancellationCharge !== null) {
            Edit Cancellation Charge
        } @else if (booking.pendingDepositCharge !== null) {
            Edit Deposit Charge
        }
    </h4>
    <button
        type="button"
        class="btn btn-close"
        (click)="dismiss()"
    >
    </button>
</div>
<div class="modal-body">
    <div class="bg-light rounded-3 p-3">
        <div class="vstack gap-2">
            <span class="fw-semibold fs-6">
                @if (charge !== null) {
                    {{ null | currencyUnit : charge.amount : 'GBP' }}
                    @if (charge.isPerCover) {
                        ({{ null | currencyUnit : charge.unitAmount : 'GBP' }}
                        x
                        {{ booking.size }})
                    }
                } @else {
                    No Charge
                }
            </span>
            <hr class="my-1"/>
            <span
                class="text-muted px-1"
            >
                @if (expiryDate !== null) {
                    Booking will be cancelled if the link expires at
                    <span class="fw-semibold">
                        {{ expiryDate | date: 'short' }}
                    </span>
                } @else {
                    Link will never expire
                }
            </span>
        </div>
    </div>
    <app-edit-charge-form
        [form]="form"
        [booking]="booking"
        [previousExpiryDate]="previousExpiryDate"
        (chargeChange)="charge = $event"
        (expiryDateChange)="expiryDate = $event"
    >
    </app-edit-charge-form>
</div>
<div
    class="modal-footer"
>
    <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="dismiss()"
    >
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-primary"
        [disabled]="form.invalid"
        (click)="save()"
    >
        @if (form.get('linkDeliveryMethod') !== null) {
            Send Link
        } @else {
            Update
        }
    </button>
</div>

<app-spinner-overlay
    [showSpinner]="viewModel.isEditingBooking"
>
</app-spinner-overlay>
<div
    class="p-3 vstack gap-3"
>
    <div
        class="vstack gap-2 gap-lg-3 mx-1 px-1"
    >
        @if (booking.customer === null) {
            <div
                class="row align-items-baseline"
            >
                <span
                    class="col-lg-3 text-secondary py-2"
                >
                    Name
                </span>
                <div
                    class="col-lg-9"
                >
                    <app-input
                        #customerName
                        [isEditable]="true"
                        [placeholder]="'Customer Name'"
                        [readonlyValue]="booking.name.length === 0 ? 'None' : booking.name"
                        [initialValue]="booking.name"
                        [inputType]="InputType.Text"
                        [validators]="viewModel.customerNameValidators"
                        (inputSubmitted)="viewModel.updateName($event)"
                    >
                    </app-input>
                </div>
            </div>
            <div
                class="row align-items-baseline"
            >
                <span
                    class="col-lg-3 text-secondary py-2"
                >
                    Email Address
                </span>
                <div
                    class="hstack align-items-center justify-content-between col-lg-9"
                >
                    <app-input
                        #emailAddress
                        [isEditable]="true"
                        [placeholder]="'Email'"
                        [readonlyValue]="booking.contact.emailAddress ?? 'None'"
                        [initialValue]="booking.contact.emailAddress"
                        [readonlyValue]="booking.contact.emailAddress ?? 'None'"
                        [initialValue]="booking.contact.emailAddress"
                        [inputType]="InputType.Email"
                        [validators]="viewModel.emailAddressValidators"
                        (inputSubmitted)="viewModel.updateEmailAddress($event)"
                        [validators]="viewModel.emailAddressValidators"
                        (inputSubmitted)="updateEmailAddress($event)"
                    >
                    </app-input>
                    <ng-container *ngIf="booking.contact.emailAddress">
                        <button
                            class="btn btn-sm btn-outline-secondary"
                            (click)="resendBookingConfirmationRequested(resendModalContent)"
                            placement="start" ngbTooltip="Resend booking confirmation email."
                        >
                            <img src="../../../../../assets/refresh/normal.svg" alt="Resend Booking Confirmation">
                        </button>
                    </ng-container>
                </div>
            </div>
            <div
                class="row align-items-baseline"
            >
                <span
                    class="col-lg-3 text-secondary py-2"
                >
                    Phone Number
                </span>
                <div
                    class="col-lg-9"
                >
                    <app-input
                        #phoneNumber
                        [isEditable]="true"
                        [placeholder]="'Phone Number'"
                        [readonlyValue]="booking.phoneNumber ? (booking.phoneNumber | phoneNumber) : 'None'"
                        [initialValue]="booking.phoneNumber"
                        [inputType]="InputType.Tel"
                        [validators]="viewModel.phoneNumberValidators"
                        (inputSubmitted)="viewModel.updatePhoneNumber($event)"
                    >
                    </app-input>
                </div>
            </div>
            <div>
                <label
                    class="text-secondary py-2"
                >
                    Attach to Customer Profile
                </label>
                <div class="vstack gap-2">
                    @if (!showCustomerSearchInput) {
                        <div class="hstack gap-2 justify-content-between">
                            <label
                                class="form-text"
                            >
                                Attach this booking to an existing customer.
                                <br/>
                                Any communication related to this booking will be sent to the customer.
                            </label>
                            <button
                                class="btn btn-sm btn-outline-secondary hstack align-items-center gap-1"
                                (click)="showCustomerSearchInput = true"
                            >
                                Link to Customer
                                <img
                                    ngSrc="assets/right/normal.svg"
                                    alt="Right"
                                    height="16"
                                    width="16"
                                />
                            </button>
                        </div>
                    } @else {
                        <div class="hstack gap-2">
                            <div class="position-relative flex-grow-1">
                                <div class="input-group">
                                    <span class="input-group-text">
                                        <img
                                            ngSrc="assets/search/normal.svg"
                                            alt="Search"
                                            height="16"
                                            width="16"
                                        />
                                    </span>
                                    <input
                                        class="form-control"
                                        type="text"
                                        placeholder="Search for a customer"
                                        [formControl]="customerSearchFormControl"
                                    />
                                </div>
                                <ng-container
                                    *ngTemplateOutlet="customerSearchList; context: { alignment: 'bottom-start' }"
                                ></ng-container>
                            </div>
                            <button
                                class="btn btn-outline-secondary"
                                (click)="showCustomerSearchInput = false"
                            >
                                Cancel
                            </button>
                        </div>
                    }
                    <div class="hstack gap-2 justify-content-between">
                        <label
                            class="form-text"
                        >
                            Create a new customer profile and attach this booking to it.
                            <br/>
                            Any communication related to this booking will be sent to the customer.
                        </label>
                        <button
                            class="btn btn-sm btn-outline-secondary hstack align-items-center gap-1"
                            (click)="createCustomer()"
                        >
                            Create Customer
                            <img
                                ngSrc="assets/plus/normal.svg"
                                alt="Right"
                                height="16"
                                width="16"
                            />
                        </button>
                    </div>
                </div>
            </div>
        } @else {
            <div class="bg-light rounded-3 p-3">
                <div class="vstack gap-2">
                    <div class="hstack gap-2 justify-content-between align-items-start">
                        <div class="vstack gap-2">
                            <div class="hstack gap-2">
                                <img
                                    ngSrc="assets/person/normal.svg"
                                    alt="Customer Profile"
                                    height="16"
                                    width="16"
                                />
                                <span class="text-muted">
                                    Customer
                                </span>
                            </div>
                            <span class="fw-semibold fs-5">
                                {{ booking.name }}
                            </span>
                        </div>
                        <button
                            class="btn btn-sm btn-outline-secondary hstack gap-1 align-self-center"
                            (click)="customerSelected.emit(booking.customer)"
                        >
                            View Profile
                            <img
                                ngSrc="assets/right/normal.svg"
                                alt="Right"
                                height="16"
                                width="16"
                            />
                        </button>
                    </div>
                    <hr class="my-1" />
                    <div class="hstack justify-content-between">
                        <div class="vstack">
                            @if (booking.emailAddress) {
                                <div class="hstack gap-2">
                                    <img
                                        ngSrc="assets/email/normal_dark.svg"
                                        alt="Email Address"
                                        height="16"
                                        width="16"
                                    />
                                    <span class="text-secondary">
                                        {{ booking.emailAddress }}
                                    </span>
                                </div>
                            }
                            @if (booking.phoneNumber) {
                                <div class="hstack gap-1">
                                    <img
                                        ngSrc="assets/phone/normal_dark.svg"
                                        alt="Phone Number"
                                        height="16"
                                        width="16"
                                    />
                                    <span class="text-secondary">
                                        {{ booking.phoneNumber | phoneNumber }}
                                    </span>
                                </div>
                            }
                            @if (booking.customer?.note()) {
                                <div class="hstack gap-2">
                                    <img
                                        ngSrc="assets/person/normal.svg"
                                        alt="Note"
                                        height="16"
                                        width="16"
                                    />
                                    <span class="text-secondary">
                                        {{ booking.customer.note() }}
                                    </span>
                                </div>
                            }
                        </div>
                        @if (booking.customer?.emailAddress) {
                            <button
                                class="btn btn-sm btn-outline-secondary"
                                (click)="resendBookingConfirmationRequested(resendModalContent)"
                            >
                                Resend Confirmation
                            </button>
                        }
                    </div>
                </div>
            </div>
            <div class="hstack gap-2 justify-content-between">
                <label
                    class="form-text"
                >
                    Unlink this booking from the customer.
                    <br/>
                    Any changes made to the customer will not affect this booking.
                </label>
                <button
                    class="btn btn-sm btn-outline-warning"
                    (click)="detachBookingFromCustomer()"
                >
                    Unlink Customer
                </button>
            </div>
        }
        <hr class="my-1" />
        <div
            class="row align-items-baseline"
        >
            <span
                class="col-lg-3 text-secondary py-2"
            >
                Marketing Opt-In
            </span>
            <div
                class="col-lg-9"
            >
                <button
                    class="btn btn-sm btn-outline-secondary disabled me-auto"
                >
                    {{ booking.agreedToMarketing.toString() | i18nSelect: agreedToMarketingMapping }}
                </button>
            </div>
        </div>
        <ng-container *ngIf="showShouldSendFeedbackRequest">
            <div class="mb-3">
                <div class="form-switch hstack">
                    <label class="form-label" for="sendFeedbackRequest">
                        Send Booking Feedback Request
                    </label>
                    <input
                        class="form-check-input ms-auto"
                        type="checkbox"
                        role="switch"
                        id="sendFeedbackRequest"
                        [checked]="viewModel.shouldSendFeedbackRequest"
                        (change)="viewModel.onChangeSendFeedbackRequest()"
                    >
                </div>
                <div class="form-text">
                    Send a feedback request to the customer after the booking has been finished.
                    <br/>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #resendModalContent>
    <p>
        Are you sure you want to resend Booking confirmation email to <b>{{booking.emailAddress}}</b> ?
    </p>
</ng-template>



<ng-template
    #customerSearchList
    let-alignment="alignment"
>
    @if (customerSearch$ | async; as customerSearch) {
        <div
            class="position-absolute position-relative bg-white shadow-lg border border-1 border-light rounded-3 z-1"
            [ngClass]="alignment"
            style="left: 36px; width: calc(100% - 36px); max-height: 450px; overflow-y: auto;"
        >
            <ul class="list-group m-0 p-0">
                @if (customerSearch.length > 0) {
                    <ng-container *ngFor="let item of customerSearch">
                        <ng-container
                            *ngTemplateOutlet="customerListItem; context: { $implicit: item }"
                        ></ng-container>
                    </ng-container>
                } @else {
                    <li class="list-group-item text-center p-3">
                        <span class="fw-semibold">
                            No existing customers found
                        </span>
                    </li>
                }
            </ul>
        </div>
    }
</ng-template>

<ng-template
    #customerListItem
    let-item
>
    <li
        class="list-group-item list-group-item-action rounded-0"
        role="button"
        (click)="attachBookingToCustomer(item)"
    >
        <div class="hstack gap-1 fw-semibold">
            {{ item.firstName }}
            <span *ngIf="item.lastName">
                {{ item.lastName }}
            </span>
        </div>
        <div class="text-secondary hstack gap-2 justify-content-between">
            <div
                *ngIf="item.emailAddress"
                class="hstack gap-1 align-items-center"
            >
                <span class="text-truncate">
                    {{ item.emailAddress }}
                </span>
            </div>
            <div
                *ngIf="item.phoneNumber"
                class="hstack gap-1 align-items-center"
            >
                <img
                    ngSrc="assets/phone/normal_dark.svg"
                    alt="Phone Number"
                    width="16"
                    height="16"
                >
                <span class="text-truncate">
                    {{ item.phoneNumber | phoneNumber }}
                </span>
            </div>
        </div>
    </li>
</ng-template>

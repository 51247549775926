import { Booking } from '@app/domain/Booking'
import { Charge } from '@app/domain/Charge'
import { DepositStatus } from '@app/domain/Deposit'
import { Venue } from '@app/domain/Venue'

export interface BookingViewModelDelegate {
    setPartyToWaiting(): void
    partiallySeatBooking(): void
    seatBooking(): void
    unseatBooking(): void
    finishBooking(): void
    reseatBooking(): void
    undoCancellation(): void
}

export class BookingViewModel {

    canAcceptBooking: boolean
    canRejectBooking: boolean
    canCancelBooking: boolean
    canNoShowBooking: boolean
    canPartiallySeatParty: boolean
    canSetPartyToWaiting: boolean
    canSeatParty: boolean
    canUnseatParty: boolean
    canFinishBooking: boolean
    canReseatParty: boolean
    canVoidBooking: boolean
    canUndoCancellation: boolean
    cancellationCharge: Charge | null
    showCancelBookingButton: boolean
    showCancelAndChargeButton: boolean
    showCancelAndRefundDepositButton: boolean
    showNoShowBookingButton: boolean
    showNoShowAndChargeButton: boolean
    showNoShowAndRefundDepositButton: boolean
    canRefundDeposit: boolean
    hasDepositRefundCutOffPassed: boolean

    constructor(
        private booking: Booking,
        private venue: Venue,
        private delegate: BookingViewModelDelegate
    ) {
        this.canAcceptBooking = booking.status().canAcceptBooking()
        this.canRejectBooking = booking.status().canRejectBooking()
        this.canCancelBooking = booking.status().canCancelBooking()
        this.canNoShowBooking = booking.status().canNoShowBooking()
        this.canSetPartyToWaiting = booking.status().canSetPartyToWaiting()
        this.canPartiallySeatParty = booking.status().canPartiallySeatParty()
        this.canSeatParty = booking.status().canSeatParty()
        this.canUnseatParty = booking.status().canUnseatParty()
        this.canFinishBooking = booking.status().canFinishBooking()
        this.canReseatParty = booking.status().canReseatParty()
        this.canVoidBooking = booking.status().canVoidBooking()
        this.canUndoCancellation = booking.status().canUndoCancellation()

        this.cancellationCharge = this.booking.activeCancellationChargeForBooking()
        this.canRefundDeposit = this.booking.deposit?.status === DepositStatus.Paid
        this.hasDepositRefundCutOffPassed = this.venue.hasDepositRefundCutOffPassed(this.booking)
        this.showCancelBookingButton = this.canCancelBooking
            && !this.cancellationCharge
            && !this.canRefundDeposit
        this.showCancelAndChargeButton = this.canCancelBooking
            && this.cancellationCharge !== null
            && !this.canRefundDeposit
        this.showCancelAndRefundDepositButton = this.canCancelBooking
            && !this.cancellationCharge
            && this.canRefundDeposit
        this.showNoShowBookingButton = this.canNoShowBooking
            && !this.cancellationCharge
            && !this.canRefundDeposit
        this.showNoShowAndChargeButton = this.canNoShowBooking
            && this.cancellationCharge !== null
            && !this.canRefundDeposit
        this.showNoShowAndRefundDepositButton = this.canNoShowBooking
            && !this.cancellationCharge
            && this.canRefundDeposit
    }

    setPartyToWaiting() {
        this.delegate.setPartyToWaiting()
    }

    partiallySeatBooking() {
        this.delegate.partiallySeatBooking()
    }

    seatBooking() {
        this.delegate.seatBooking()
    }

    unseatBooking() {
        this.delegate.unseatBooking()
    }

    finishBooking() {
        this.delegate.finishBooking()
    }

    reseatBooking() {
        this.delegate.reseatBooking()
    }

    undoCancellation() {
        this.delegate.undoCancellation()
    }
}

import { Charge } from './Charge'
import { DateComponent } from './DateComponent'
import { DateComponentUnit } from '@app/domain/DateComponentUnit'
import { PartySizeDuration } from './PartySizeDuration'
import { Time } from '@app/domain/Time'
import { differenceInMonths, differenceInWeeks, getDay, lastDayOfMonth, subDays } from 'date-fns'

export enum MonthlyRepetitionType {
    DayOfMonth, // day of month of start date
    DayOfWeek, // day of week and week of month of start date
}

export class Event {

    constructor(
        public id: string,
        public displayName: string,
        public displayOrder: number,
        public description: string,
        public bookingDurations: PartySizeDuration[],
        public imageId: string | null,
        public cancellationChargeAmount: number | null,
        public cancellationChargePartyAmount: number | null,
        public cancellationChargeCutOffHours: number | null,
        public depositAmount: number | null,
        public depositTableCapacityAmount: number | null,
        public depositRefundCutOffDays: number | null,
        public diningInformation: string | null,
        public isExclusive: boolean,
        public notReservableOnline: boolean,
        public notReservableOnlineMessage: string | null,
        public forceSendMerchantEmail: boolean,
        public contributesToKitchenVelocity: boolean,
        public preBookingWindowMinutes: number | null,
        public preBookingModificationWindowMinutes: number | null,
        public recurrence: EventRecurrence,
        public tableIds: string[][],
        public questionIds: string[]
    ) { }

    removeImage() {
        this.imageId = null
    }

    cancellationChargeForPartySize(partySize: number): Charge | null {
        if (this.cancellationChargeAmount === null) {
            return null
        }
        if (this.cancellationChargePartyAmount !== null) {
            return new Charge(
                partySize,
                null,
                null,
                this.cancellationChargePartyAmount,
                false
            )
        }
        return new Charge(
            partySize,
            null,
            null,
            this.cancellationChargeAmount,
            true
        )
    }

    depositChargeForPartySize(partySize: number): Charge | null {
        if (this.depositAmount === null) {
            return null
        }
        return new Charge(
            partySize,
            null,
            null,
            this.depositAmount,
            true
        )
    }
}

export class EventRecurrence {

    constructor(
        public startDates: Date[],
        public dateComponent: DateComponent | null,
        public weeklyRepeatsOnDaysOfWeek: number[] | null,
        public monthlyRepetitionType: MonthlyRepetitionType | null,
        public exceptions: EventException[],
        public endDate: Date | null,
        public startTime: Time | null,
        public endTime: Time | null
    ) { }

    appliesToDate(dateTime: Date): boolean {
        const date = dateTime
        const startDate = this.earliestStartDate()
        if (date < startDate) {
            return false
        }
        if (this.endDate && date > this.endDate) {
            return false
        }
        if (!this.dateComponent) {
            return this.startDates.some(start => start.getTime() === date.getTime())
        }
        if (this.dateComponent?.unit === DateComponentUnit.DAY_OF_WEEK) {
            if (!this.weeklyRepeatsOnDaysOfWeek) {
                return false
            }
            if (!this.weeklyRepeatsOnDaysOfWeek.includes(date.getDay())) {
                return false
            }
            if (!this.doesDateOccurInWeeklySchedule(date, this.dateComponent)) {
                return false
            }
        }
        if (this.dateComponent?.unit === DateComponentUnit.DAY_OF_MONTH) {
            if (this.monthlyRepetitionType === null) {
                return false
            }
            if (this.monthlyRepetitionType === MonthlyRepetitionType.DayOfMonth) {
                if (date.getDate() !== startDate.getDate()) {
                    return false
                }
            }
            if (this.monthlyRepetitionType === MonthlyRepetitionType.DayOfWeek) {
                if (date.getDay() !== startDate.getDay()) {
                    return false
                }
                const scheduledOrdinalWeekOfMonth = this.getScheduledOrdinalWeekOfMonth(date)
                const dateOrdinalWeekOfMonth = date.getDate()
                if (scheduledOrdinalWeekOfMonth !== dateOrdinalWeekOfMonth) {
                    return false
                }
            }
            if (!this.doesDateOccurInMonthlySchedule(date, this.dateComponent)) {
                return false
            }
        }
        return true
    }

    getLastMatchingDayOfMonth(referenceDate: Date, matchDayDate: Date) {
        const lastDay = lastDayOfMonth(referenceDate)
        const targetDayOfWeek = getDay(matchDayDate)
        let currentDayOfWeek = getDay(lastDay)
        let difference = currentDayOfWeek - targetDayOfWeek
        if (difference < 0) {
            difference += 7
        }
        const lastMatchingDay = subDays(lastDay, difference)
        return lastMatchingDay
    }

    earliestStartDate(): Date {
        return this.startDates.reduce((earliest, current) => {
            return current < earliest ? current : earliest
        })
    }

    private getScheduledOrdinalWeekOfMonth(date: Date): number {
        const startDate = this.earliestStartDate()
        const ordinalStartDateWeekOfMonth = startDate.getDate()
        const largestWeekOfMonthOnWeekday = this.getLastMatchingDayOfMonth(date, startDate)
            .getDate()
        if (ordinalStartDateWeekOfMonth > largestWeekOfMonthOnWeekday) {
            return largestWeekOfMonthOnWeekday
        }
        return ordinalStartDateWeekOfMonth
    }

    private doesDateOccurInWeeklySchedule(
        date: Date,
        dateComponent: DateComponent
    ): boolean {
        const startDate = this.earliestStartDate()
        const startOfStartDateWeek = startDate
        const startOfDateWeek = date
        const weeksBetween = differenceInWeeks(startOfDateWeek, startOfStartDateWeek)
        const weeksBetweenIsMultiple = weeksBetween % dateComponent.value === 0
        return weeksBetweenIsMultiple
    }

    private doesDateOccurInMonthlySchedule(
        date: Date,
        dateComponent: DateComponent
    ): boolean {
        const startDate = this.earliestStartDate()
        const startOfStartDateMonth = startDate
        const startOfDateMonth = date
        const monthsBetween = differenceInMonths(startOfDateMonth, startOfStartDateMonth)
        const monthsBetweenIsMultiple = monthsBetween % dateComponent.value === 0
        return monthsBetweenIsMultiple
    }
}

export class EventException {

    constructor(
        public id: string,
        public date: Date
    ) { }
}

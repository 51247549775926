import { Booking } from '../../../../domain/Booking'
import { BookingService } from '../../../../services/booking.service'
import { Business } from '../../../../domain/Business'
import { Charge } from '../../../../domain/Charge'
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core'
import { ContextService } from '../../../../services/context.service'
import { DiaryPreferencesService } from '../../../bookings/services/diary-preferences.service'
import { DurationUnit } from '../../pipes/duration.pipe'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastService } from '../../../../services/toast.service'
import { Venue } from '../../../../domain/Venue'
import { finalize, map, takeUntil, startWith } from 'rxjs'
import DineroFactory from 'dinero.js'

@Component({
    selector: 'app-add-charge',
    templateUrl: './add-charge.component.html',
})
export class AddChargeComponent implements OnInit {

    @Input() chargeType?: 'cancellationCharge' | 'depositCharge'
    @Input() business!: Business
    @Input() venue!: Venue
    @Input() booking!: Booking
    @Output() bookingChanged = new EventEmitter<Booking>()
    isAddingCharge = false
    form!: FormGroup
    previousExpiryDate: Date | null = null
    expiryDate: Date | null = null
    charge: Charge | null = null
    private onDestroy$ = new EventEmitter<void>()

    constructor(
        private contextService: ContextService,
        private bookingService: BookingService,
        protected diaryPreferencesService: DiaryPreferencesService,
        private fb: FormBuilder,
        private modal: NgbActiveModal,
        private toastService: ToastService
    ) { }

    ngOnInit() {
        this.previousExpiryDate = null
        const expiryDate = this.booking.expiryDate()
        if (expiryDate !== null) {
            this.previousExpiryDate = new Date(expiryDate)
        }
        this.makeFormWithBookingExistingPendingCharge()
        this.makeFormWithDefaultCharge()
    }

    dismiss() {
        this.modal.dismiss()
    }

    save() {
        if (this.form === null) {
            return
        }
        if (this.form.invalid) {
            return
        }
        const isCreatingNewCharge = this.booking.pendingCancellationCharge === null
            && this.booking.pendingDepositCharge === null
        if (isCreatingNewCharge) {
            this.sendLink()
        } else {
            this.updateCharge()
        }
    }

    private sendLink() {
        const amount = this.form.get('amount')?.value
        const unitAmount = Math.round(amount * 100)
        const charge = new Charge(
            this.booking.size,
            null,
            null,
            unitAmount,
            this.form.get('isPerCover')?.value
        )
        this.addCharge(charge)
    }

    private updateCharge() {
        const organisation = this.contextService.getOrganisation()
        if (organisation === null) {
            return
        }
        const amount = this.form.get('amount')?.value
        const unitAmount = Math.round(amount * 100)
        const isPerCover = this.form.get('isPerCover')?.value
        let expiryMinutes = this.form.get('expiryMinutes')?.value
        if (expiryMinutes === -1) {
            expiryMinutes = undefined
        } else if (expiryMinutes === null || expiryMinutes === 'null') {
            expiryMinutes = null
        }
        let expiryDate = undefined
        if (expiryMinutes !== undefined) {
            expiryDate = this.expiryDate
        }
        const charge = new Charge(
            this.booking.size,
            null,
            null,
            unitAmount,
            isPerCover
        )
        this.isAddingCharge = true
        this.bookingService.updateCharge(
            organisation,
            this.business,
            this.venue,
            this.booking,
            charge,
            expiryDate,
        )
            .pipe(
                finalize(() => this.isAddingCharge = false),
                takeUntil(this.onDestroy$)
            )
            .subscribe({
                next: (booking) => {
                    this.bookingChanged.emit(booking)
                },
            })
    }

    private addCharge(charge: Charge) {
        if (this.chargeType === 'cancellationCharge') {
            this.addCancellationCharge(charge)
        } else {
            this.addDepositCharge(charge)
        }
    }

    private addCancellationCharge(charge: Charge) {
        const organisation = this.contextService.getOrganisation()
        if (organisation === null) {
            return
        }
        const linkDeliveryMethod = this.form.get('linkDeliveryMethod')?.value
        const sendViaEmail = linkDeliveryMethod === 'email'
        const sendViaSms = linkDeliveryMethod === 'sms'
        this.isAddingCharge = true
        this.bookingService.addCancellationCharge(
            organisation,
            this.business,
            this.venue,
            this.booking,
            charge,
            sendViaEmail,
            sendViaSms,
            this.expiryDate
        )
            .pipe(
                finalize(() => this.isAddingCharge = false),
                takeUntil(this.onDestroy$)
            )
            .subscribe({
                next: (booking) => {
                    this.bookingChanged.emit(booking)
                },
            })
    }

    private addDepositCharge(charge: Charge) {
        const organisation = this.contextService.getOrganisation()
        if (organisation === null) {
            return
        }
        const linkDeliveryMethod = this.form.get('linkDeliveryMethod')?.value
        const sendViaEmail = linkDeliveryMethod === 'email'
        const sendViaSms = linkDeliveryMethod === 'sms'
        this.isAddingCharge = true
        this.bookingService.addDepositCharge(
            organisation,
            this.business,
            this.venue,
            this.booking,
            charge,
            sendViaEmail,
            sendViaSms,
            this.expiryDate
        )
            .pipe(
                finalize(() => this.isAddingCharge = false),
                takeUntil(this.onDestroy$)
            )
            .subscribe({
                next: (booking) => {
                    this.bookingChanged.emit(booking)
                },
            })
    }

    private makeFormWithBookingExistingPendingCharge() {
        const charge = this.booking.pendingCancellationCharge ?? this.booking.pendingDepositCharge
        const chargeType = this.booking.pendingDepositCharge ? 'depositCharge' : 'cancellationCharge'
        if (charge === null) {
            return
        }
        const isPerCover = charge.isPerCover
        const money = DineroFactory({
            amount: charge.unitAmount,
            currency: 'GBP',
        })
        let currentExpiryMinutes = null
        const expiryDate = this.previousExpiryDate
        if (expiryDate !== null) {
            // -1 is a special value that means don't change the expiry time
            currentExpiryMinutes = -1
        }
        this.form = this.fb.group({
            isPerCover: [isPerCover, Validators.required],
            amount: [money.toRoundedUnit(1), chargeType === 'cancellationCharge' ? null : [Validators.required, Validators.min(0.01)]],
            chargeType: [chargeType, Validators.required],
            expiryMinutes: [currentExpiryMinutes, Validators.required],
        })
    }

    private makeFormWithDefaultCharge() {
        if (this.booking.pendingCancellationCharge !== null) {
            return
        }
        if (this.booking.pendingDepositCharge !== null) {
            return
        }
        if (this.chargeType === null) {
            return
        }
        const defaultCancellationCharge = this.venue.cancellationChargeForPartySize(
            this.booking.eventId,
            this.booking.size
        )
        const defaultDepositCharge = this.venue.depositChargeForPartySize(
            this.booking.eventId,
            this.booking.size
        )
        const defaultCharge = this.chargeType === 'cancellationCharge' ? defaultCancellationCharge : defaultDepositCharge
        let defaultDeliveryMethod = null
        if (this.booking.emailAddress !== null) {
            defaultDeliveryMethod = 'email'
        } else if (this.booking.phoneNumber !== null) {
            defaultDeliveryMethod = 'sms'
        }
        const defaultExpiryMinutes = this.venue.diaryPreferences.pendingPaymentExpiryMinutes
        const isPerCover = (defaultCharge !== null) ? defaultCharge.isPerCover : true
        const money = (defaultCharge !== null) ? DineroFactory({
            amount: defaultCharge.unitAmount,
            currency: 'GBP',
        }) : null
        this.form = this.fb.group({
            isPerCover: [isPerCover, Validators.required],
            amount: [money?.toRoundedUnit(1), this.chargeType === 'cancellationCharge' ? null : [Validators.required, Validators.min(0.01)]],
            linkDeliveryMethod: [defaultDeliveryMethod, Validators.required],
            expiryMinutes: [defaultExpiryMinutes, Validators.required],
            chargeType: [this.chargeType, Validators.required],
        })
    }

    protected readonly DurationUnit = DurationUnit
}

import { BookingReason } from '../../../domain/BookingReason'
import { ElementChoice } from '../../shared/components/element-choice-select/element-choice'

export class BookingReasonChoice implements ElementChoice<BookingReason | null> {

    constructor(
        private reason: BookingReason | null
    ) { }

    get id(): string {
        if (!this.reason) {
            return ''
        }
        return this.reason.id
    }

    get displayName(): string {
        if (!this.reason) {
            return 'None'
        }
        return this.reason.displayName
    }

    get value(): BookingReason | null {
        return this.reason
    }
}

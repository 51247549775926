<hr class="my-0" />
<div
    class="vstack"
>
    <div
        class="p-3 vstack gap-3"
    >
        <div
            class="mx-1 vstack gap-2 gap-lg-3"
        >
            <div
                class="row align-items-baseline"
            >
                <span
                    class="col-lg-3 text-secondary py-2"
                >
                    Customer Name
                </span>
                <div
                    class="col-lg-9"
                >
                    <app-input
                        #customerName
                        [isEditable]="true"
                        [placeholder]="'Customer Name'"
                        [readonlyValue]="customer.name().length === 0 ? 'None' : customer.name()"
                        [initialValue]="customer.name()"
                        [inputType]="InputType.Text"
                        [validators]="customerNameValidators"
                        (inputSubmitted)="updateName($event)"
                    >
                    </app-input>
                </div>
            </div>
            <div
                class="row align-items-baseline"
            >
                <span
                    class="col-lg-3 text-secondary py-2"
                >
                    Email Address
                </span>
                <div
                    class="hstack align-items-center justify-content-between col-lg-9"
                >
                    <app-input
                        #emailAddress
                        [isEditable]="true"
                        [placeholder]="'None'"
                        [readonlyValue]="customer.emailAddress ?? 'None'"
                        [initialValue]="customer.emailAddress"
                        [inputType]="InputType.Email"
                        [validators]="emailAddressValidators"
                        (inputSubmitted)="updateEmailAddress($event)"
                    >
                    </app-input>
                </div>
            </div>
            <div
                class="row align-items-baseline"
            >
                <span
                    class="col-lg-3 text-secondary py-2"
                >
                    Phone
                </span>
                <div
                    class="col-lg-9"
                >
                    <app-input
                        #phoneNumber
                        [isEditable]="true"
                        [placeholder]="'Phone Number'"
                        [readonlyValue]="customer.phoneNumber ? (customer.phoneNumber | phoneNumber) : 'None'"
                        [initialValue]="customer.phoneNumber"
                        [inputType]="InputType.Tel"
                        [validators]="phoneNumberValidators"
                        (inputSubmitted)="updatePhoneNumber($event)"
                    >
                    </app-input>
                </div>
            </div>
        </div>
    </div>
    <hr class="my-1" />
    <div class="p-3">
        <div
            class="row align-items-baseline"
        >
            <span
                class="col-lg-3 text-secondary py-2"
            >
                Customer Note
            </span>
            <div
                class="col-lg-9 hstack gap-2 align-items-start"
            >
                <app-textarea
                    #note
                    style="display: contents;"
                    [placeholder]="'Add a Customer Note'"
                    [isEditable]="true"
                    [initialValue]="customer.note()"
                    [rows]="3"
                    (textSubmitted)="updateNote($event)"
                >
                </app-textarea>
            </div>
            <label class="form-text">
                Customer Notes are private to you, and appear on any bookings linked to this customer.
            </label>
        </div>
    </div>
</div>


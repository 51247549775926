import * as d3 from 'd3'
import { Area } from '@app/domain/Area'
import { Booking } from '@app/domain/Booking'
import { BookingService } from '@services/booking.service'
import { BookingStatus, BookingStatusType } from '@app/domain/BookingStatus'
import {
    BookingViewModel,
    BookingViewModelDelegate,
} from '@app/features/bookings/components/booking-view-model'
import { Business } from '@app/domain/Business'
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output, TemplateRef,
} from '@angular/core'
import {
    ConfirmUndoCancellationComponent,
} from '../confirm-undo-cancellation/confirm-undo-cancellation.component'
import { ContextService } from '@services/context.service'
import { Customer } from '../../../../domain/Customer'
import { DiaryPreferencesService } from '@app/features/bookings/services/diary-preferences.service'
import { Dinero } from 'dinero.js'
import { EventService } from '@services/event.service'
import { Helper } from '@app/helpers/utilities/helper'
import {
    ModalActionType,
    ModalComponent,
} from '@app/features/shared/components/modal/modal.component'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Observable, Subject, finalize, mergeMap, takeUntil, tap } from 'rxjs'
import { Organisation } from '@app/domain/Organisation'
import { PaymentsService } from '@services/payments.service'
import { ToastBuilder } from '@app/domain/ToastBuilder'
import { ToastService } from '@services/toast.service'
import { ToastType } from '@app/domain/Toast'
import { Venue } from '@app/domain/Venue'
import { VenueSchedule } from '@app/domain/VenueSchedule'
import { v4 as uuidv4 } from 'uuid'

export type BookingChangedMetadata = {
    changeHasFinishedInteraction: boolean
}

@Component({
    selector: 'app-booking',
    templateUrl: './booking.component.html',
})
export class BookingComponent implements
    OnInit,
    OnDestroy,
    BookingViewModelDelegate {

    @Input() booking!: Booking
    @Input() organisation!: Organisation
    @Input() business!: Business
    @Input() venue!: Venue
    @Input() otherBookings!: Booking[]
    @Input() schedule!: VenueSchedule | null
    @Output() bookingBeingChanged = new EventEmitter<boolean>()
    @Output() bookingChanged = new EventEmitter<[Booking, Partial<BookingChangedMetadata>]>()
    @Output() customerSelected = new EventEmitter<Customer>()
    viewModel!: BookingViewModel
    page: 'details' | 'customer' | 'payments' | 'history' = 'details'
    isEditingBooking = false
    partySizePluralMapping = {
        '=0': 'No people',
        '=1': '1 person',
        other: '# people',
    }
    private area!: Area
    private onDestroy = new Subject<void>()

    constructor(
        private contextService: ContextService,
        protected diaryPreferencesService: DiaryPreferencesService,
        private bookingService: BookingService,
        private eventService: EventService,
        private paymentService: PaymentsService,
        private toastService: ToastService,
        private modal: NgbActiveModal,
        private modalService: NgbModal
    ) { }

    ngOnInit() {
        this.area = this.venue.areaBookingIsIn(this.booking)!
        this.viewModel = new BookingViewModel(
            this.booking,
            this.venue,
            this
        )
        this.bookingChanged
            .pipe(
                takeUntil(this.onDestroy)
            )
            .subscribe(([booking, _]) => {
                this.booking = booking
                this.area = this.venue.areaBookingIsIn(this.booking)!
                this.viewModel = new BookingViewModel(
                    this.booking,
                    this.venue,
                    this
                )
            })
        this.bookingBeingChanged
            .pipe(
                takeUntil(this.onDestroy)
            )
            .subscribe(isBeingChanged => {
                this.isEditingBooking = isBeingChanged
            })
    }

    ngOnDestroy() {
        this.onDestroy.next()
        this.onDestroy.complete()
    }

    detailsTabSelected() {
        this.page = 'details'
    }

    customerTabSelected() {
        this.page = 'customer'
    }

    paymentsTabSelected() {
        this.page = 'payments'
    }

    historyTabSelected() {
        this.page = 'history'
    }

    acceptBooking() {
        this.bookingBeingChanged.emit(true)
        this.bookingService.acceptBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.bookingBeingChanged.emit(false))
            )
            .subscribe(booking => {
                this.eventService.publishEvent({ type: 'booking_accepted' })
                this.bookingChanged.emit(([booking, {}]))
            })
    }

    rejectBooking(template: TemplateRef<any>) {
        const modal = this.modalService.open(ModalComponent)
        const component = modal.componentInstance as ModalComponent
        component.title = 'Reject Booking'
        component.actionConfiguration = {
            collectText: true,
            textLabel: 'Reason for rejection',
            textPlaceholder: '(Optional)',
            textRequired: false,
        }
        component.actionType = 'confirm'
        component.actionButtonTitle = 'Reject'
        component.cancelButtonTitle = 'Cancel'
        component.contentTemplate = template
        component.actionSelected
            .pipe(
                takeUntil(this.onDestroy),
                tap(() => component.isPerformingAction = true),
                finalize(() => component.isPerformingAction = false),
                mergeMap(reason => {
                    return this.rejectBookingWithReason(reason ?? null)
                })
            )
            .subscribe(booking => {
                modal.close()
                this.eventService.publishEvent({ type: 'booking_rejected' })
                this.bookingChanged.emit([booking, {}])
            })
    }

    private rejectBookingWithReason(reason: string | null) {
        this.bookingBeingChanged.emit(true)
        return this.bookingService.rejectBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id,
            reason
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.bookingBeingChanged.emit(false))
            )
    }

    makeCancelModal(
        title: string,
        actionType: ModalActionType,
        actionButtonTitle: string,
        cancelButtonTitle: string,
        cancelPerformsAction: boolean,
        modalTemplate: TemplateRef<any>,
        paymentOptions: Partial<{ mechanism: 'cancellationCharge' | 'depositRefund' }>
    ) {
        const user = this.contextService.getUser()
        if (!user) {
            return
        }
        const modal = this.modalService.open(ModalComponent)
        const component = modal.componentInstance as ModalComponent
        component.title = title
        component.actionType = actionType
        component.actionButtonTitle = actionButtonTitle
        component.cancelButtonTitle = cancelButtonTitle
        component.cancelPerformsAction = cancelPerformsAction
        component.contentTemplate = modalTemplate
        component.actionSelected
            .pipe(
                takeUntil(this.onDestroy),
                tap(() => component.isPerformingAction = true),
                finalize(() => component.isPerformingAction = false),
                mergeMap(() => {
                    return this.bookingService.cancelBooking(
                        this.business.id,
                        this.venue.id,
                        this.area.id,
                        this.booking.id,
                        user,
                        paymentOptions
                    )
                })
            )
            .subscribe({
                next: booking => {
                    modal.close()
                    this.bookingChanged.emit([booking, {}])
                },
                error: _ =>{
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t cancel booking. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
        component.cancelSelected
            .pipe(
                takeUntil(this.onDestroy),
                tap(() => component.isPerformingAction = true),
                finalize(() => component.isPerformingAction = false),
                mergeMap(() => {
                    return this.bookingService.cancelBooking(
                        this.business.id,
                        this.venue.id,
                        this.area.id,
                        this.booking.id,
                        user,
                        {}
                    )
                })
            )
            .subscribe({
                next: booking => {
                    modal.close()
                    this.bookingChanged.emit([booking, {}])
                },
                error: _ =>{
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t cancel booking. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    makeNoShowModal(
        title: string,
        actionType: ModalActionType,
        actionButtonTitle: string,
        cancelButtonTitle: string,
        cancelPerformsAction: boolean,
        modalTemplate: TemplateRef<any>,
        paymentOptions: Partial<{ mechanism: 'cancellationCharge' | 'depositRefund' }>
    ){
        const modal = this.modalService.open(ModalComponent)
        const component = modal.componentInstance as ModalComponent
        component.title = title
        component.actionType = actionType
        component.actionButtonTitle = actionButtonTitle
        component.cancelButtonTitle = cancelButtonTitle
        component.cancelPerformsAction = cancelPerformsAction
        component.contentTemplate = modalTemplate
        component.actionSelected
            .pipe(
                takeUntil(this.onDestroy),
                tap(() => component.isPerformingAction = true),
                finalize(() => component.isPerformingAction = false),
                mergeMap(() => {
                    return this.bookingService.noShowBooking(
                        this.business.id,
                        this.venue.id,
                        this.area.id,
                        this.booking.id,
                        paymentOptions
                    )
                })
            )
            .subscribe({
                next: booking => {
                    modal.close()
                    this.bookingChanged.emit([booking, {}])
                },
                error: _ =>{
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t no show booking. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
        component.cancelSelected
            .pipe(
                takeUntil(this.onDestroy),
                tap(() => component.isPerformingAction = true),
                finalize(() => component.isPerformingAction = false),
                mergeMap(() => {
                    return this.bookingService.noShowBooking(
                        this.business.id,
                        this.venue.id,
                        this.area.id,
                        this.booking.id,
                        {}
                    )
                })
            )
            .subscribe({
                next: booking => {
                    modal.close()
                    this.bookingChanged.emit([booking, {}])
                },
                error: _ => {
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t no show booking. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    cancelAndChargeBooking(modalTemplate: TemplateRef<any>) {
        this.makeCancelModal(
            'Cancel Booking',
            'confirm',
            'Charge and Cancel',
            'Cancel Without Charge',
            true,
            modalTemplate,
            {
                mechanism: 'cancellationCharge',
            }
        )
    }

    cancelAndRefundBookingDeposit(modalTemplate: TemplateRef<any>) {
        this.makeCancelModal(
            'Cancel Booking',
            'confirm',
            'Refund Deposit and Cancel',
            'Cancel Without Refund',
            true,
            modalTemplate,
            {
                mechanism: 'depositRefund',
            }
        )
    }

    cancelBookingWithoutCharging(modalTemplate: TemplateRef<any>) {
        this.makeCancelModal(
            'Cancel Booking',
            'confirm',
            'Cancel Booking',
            'Cancel',
            false,
            modalTemplate,
            {}
        )
    }

    noShowBookingWithCharge(modalTemplate: TemplateRef<any>) {
        this.makeNoShowModal(
            'No Show Booking',
            'confirm',
            'Charge and No Show',
            'No Show Without Charge',
            true,
            modalTemplate,
            {
                mechanism: 'cancellationCharge',
            }
        )
    }

    NoShowBookingAndRefundDeposit(modalTemplate: TemplateRef<any>) {
        this.makeNoShowModal(
            'No Show Booking',
            'confirm',
            'Refund Deposit and No Show',
            'No Show Without Refund',
            true,
            modalTemplate,
            {
                mechanism: 'depositRefund',
            }
        )
    }

    noShowBookingWithoutCharging(modalTemplate: TemplateRef<any>) {
        this.makeNoShowModal(
            'No Show Booking',
            'confirm',
            'No Show Booking',
            'Cancel',
            false,
            modalTemplate,
            {}
        )
    }

    voidBooking(modalTemplate: TemplateRef<any>) {
        const modal = this.modalService.open(ModalComponent)
        const component = modal.componentInstance as ModalComponent
        component.title = 'Void Booking'
        component.actionType = 'confirm'
        component.actionButtonTitle = 'Void Booking'
        component.cancelButtonTitle = 'Cancel'
        component.contentTemplate = modalTemplate
        component.actionType = 'delete'
        component.actionSelected
            .pipe(
                takeUntil(this.onDestroy),
                tap(() => component.isPerformingAction = true),
                finalize(() => component.isPerformingAction = false),
                mergeMap(() => {
                    return this.bookingService.voidBooking(
                        this.business.id,
                        this.venue.id,
                        this.booking
                    )
                })
            )
            .subscribe({
                next: booking => {
                    modal.close()
                    this.bookingChanged.emit([booking, {}])
                },
                error: _ => {
                    //TODO Track the error
                    this.showError(
                        'Couldn\'t void booking. Please try again.',
                        'Booking Update Error'
                    )
                },
            })
    }

    setPartyToWaiting() {
        this.bookingBeingChanged.emit(true)
        this.bookingService.setPartyToWaiting(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.bookingBeingChanged.emit(false))
            )
            .subscribe(booking => {
                this.bookingChanged.emit([booking, {}])
            })
    }

    partiallySeatBooking() {
        this.bookingBeingChanged.emit(true)
        const bringForward = this.shouldBringForwardBooking()
        if (bringForward) {
            this.seatBookingAndBringForward(
                bringForward.newStart,
                bringForward.newDuration,
                BookingStatusType.PartiallySeated
            )
            return
        }
        this.bookingService.partiallySeatBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.bookingBeingChanged.emit(false))
            )
            .subscribe(booking => {
                this.bookingChanged.emit([booking, {}])
            })
    }

    seatBooking() {
        this.bookingBeingChanged.emit(true)
        const bringForward = this.shouldBringForwardBooking()
        if (bringForward) {
            this.seatBookingAndBringForward(
                bringForward.newStart,
                bringForward.newDuration,
                BookingStatusType.Seated
            )
            return
        }
        this.bookingService.seatBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.bookingBeingChanged.emit(false))
            )
            .subscribe(booking => {
                this.bookingChanged.emit([booking, {}])
            })
    }

    unseatBooking() {
        this.bookingBeingChanged.emit(true)
        this.bookingService.unseatBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.bookingBeingChanged.emit(false))
            )
            .subscribe(booking => {
                this.bookingChanged.emit([booking, {}])
            })
    }

    finishBooking() {
        const now = new Date()
        now.setMinutes(now.getMinutes(), 0, 0)
        const isFinishingEarly = this.booking.isInProgressAtDate(now)
        let finishBooking: Observable<Booking>
        if (isFinishingEarly) {
            const closestBookingSlot = this.venue.bookingSlotClosestToDate(
                now,
                Helper.startOfDay(now),
                Helper.endOfDay(now),
                (startDate, endDate, interval) => {
                    return d3.timeMinute
                        .every(interval)!
                        .range(startDate, endDate)
                        .concat(endDate)
                }
            )
            if (!closestBookingSlot) {
                return
            }
            const bookingSlotEdge = this.venue.closestEdgeToEndBookingAt(now, closestBookingSlot, this.booking)
            const minutesUntilClosestBookingSlot = Helper.minutesBetweenDates(now, bookingSlotEdge)
            if (minutesUntilClosestBookingSlot === undefined) {
                return
            }
            finishBooking = this.bookingService.finishBookingEarly(
                this.business.id,
                this.venue.id,
                this.booking.id,
                minutesUntilClosestBookingSlot
            )
        } else {
            finishBooking = this.bookingService.finishBooking(
                this.business.id,
                this.venue.id,
                this.area.id,
                this.booking.id
            )
        }
        this.bookingBeingChanged.emit(true)
        finishBooking
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.bookingBeingChanged.emit(false))
            )
            .subscribe(booking => {
                this.bookingChanged.emit([booking, {}])
            })
    }

    reseatBooking() {
        this.bookingBeingChanged.emit(true)
        this.bookingService.reseatBooking(
            this.business.id,
            this.venue.id,
            this.area.id,
            this.booking.id
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.bookingBeingChanged.emit(false))
            )
            .subscribe(booking => {
                this.bookingChanged.emit([booking, {}])
            })
    }

    undoCancellation() {
        this.bookingBeingChanged.emit(true)
        const modal = this.modalService.open(ConfirmUndoCancellationComponent)
        const component = modal.componentInstance as ConfirmUndoCancellationComponent
        component.venue = this.venue
        component.booking = this.booking
        modal.closed
            .pipe(takeUntil(this.onDestroy))
            .subscribe(booking => {
                if (booking) {
                    this.bookingChanged.emit([booking, {}])
                    this.toastService.show(
                        new ToastBuilder('Cancellation was successfully undone')
                            .withType(ToastType.Success)
                            .withHeader('Undo Cancellation Successful')
                            .build()
                    )
                }
                this.bookingBeingChanged.emit(false)
            })
        modal.dismissed
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.bookingBeingChanged.emit(false)
            })
    }

    chargeCustomer(amount: Dinero) {
        this.bookingBeingChanged.emit(true)
        this.paymentService.chargeCancellationFee(
            this.business,
            this.booking,
            amount
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.bookingBeingChanged.emit(false))
            )
            .subscribe(_ => {
                this.toastService.show(
                    new ToastBuilder(`Customer was charged ${amount.toFormat()}`)
                        .withType(ToastType.Success)
                        .withHeader('Charge Successful')
                        .build()
                )
            })
    }

    dismissClicked() {
        this.modal.dismiss()
    }

    private showError(message: string, title?: string) {
        this.showToast(ToastType.Danger, message, title)
    }

    private showToast(toastType: ToastType, message: string, title?: string) {
        let  toastBuilder = new ToastBuilder(message)
            .withType(toastType)

        if (title){
            toastBuilder = toastBuilder.withHeader(title)
        }

        this.toastService.show(toastBuilder.build())
    }

    private seatBookingAndBringForward(
        newStart: Date,
        newDuration: number,
        newStatusType: BookingStatusType.PartiallySeated | BookingStatusType.Seated
    ) {
        const organisation = this.contextService.getOrganisation()
        if (!organisation) {
            return
        }
        const status = new BookingStatus(
            uuidv4(),
            new Map(),
            new Date(),
            newStatusType
        )
        this.bookingService.changeBookingDate(
            organisation.id,
            this.business.id,
            this.venue.id,
            this.booking.id,
            newStart,
            newDuration,
            undefined,
            status
        )
            .pipe(
                takeUntil(this.onDestroy),
                finalize(() => this.bookingBeingChanged.emit(false))
            )
            .subscribe(booking => {
                this.bookingChanged.emit([booking, {}])
            })
    }

    private shouldBringForwardBooking(): { newStart: Date, newDuration: number } | false {
        const organisation = this.contextService.getOrganisation()
        if (!organisation) {
            return false
        }
        const area = this.venue.areaWithTableId(this.booking.tableIds[0])
        if (!area) {
            return false
        }
        const schedule = organisation.scheduleUsedInAreaOnDate(area, this.booking.start)
        if (!schedule) {
            return false
        }
        const bringForwardCutOffMinutes = 60
        const now = new Date()
        now.setMinutes(now.getMinutes(), 0, 0)
        const bookingSlotExtent = schedule.bookingSlotExtentForDate(now)
        const closestBookingSlot = this.venue.bookingSlotClosestToDate(
            now,
            bookingSlotExtent[0],
            bookingSlotExtent[1],
            (startDate, endDate, interval) => {
                return d3.timeMinute
                    .every(interval)!
                    .range(startDate, endDate)
                    .concat(endDate)
            }
        )
        if (!closestBookingSlot) {
            return false
        }
        const start = closestBookingSlot.closestEdgeToDateTime(now, this.venue.bookingInterval)
        const minutesUntilBookingStarts = Helper.minutesBetweenDates(start, this.booking.start)
        if (minutesUntilBookingStarts === undefined) {
            return false
        }
        if (minutesUntilBookingStarts > bringForwardCutOffMinutes) {
            return false
        }
        if (minutesUntilBookingStarts < 0) {
            return false
        }
        const tables = this.area.tablesUsedForBooking(this.booking)
        const noBookingsOccurBetweenNowAndStart = this.otherBookings
            .filter(booking => booking.occupiesTableBetweenDates(start, this.booking.start, tables))
            .length === 0
        if (!noBookingsOccurBetweenNowAndStart) {
            return false
        }
        return {
            newStart: start,
            newDuration: this.booking.duration + minutesUntilBookingStarts,
        }
    }
}

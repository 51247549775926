import { Booking } from '@app/domain/Booking'
import { BookingRequest } from '../../../reports/domain/BookingRequest'
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import { HasBookingStatus } from '../../../bookings/pipes/booking-status-filter.pipe'

export type Options = {
    showBookingDateTime: boolean,
    showBookingTime: boolean,
}

@Component({
    selector: 'app-bookings-list',
    templateUrl: './bookings-list.component.html',
})
export class BookingsListComponent implements OnChanges {

    @Input() bookings: HasBookingStatus[] = []
    @Input() options?: Partial<Options>
    @Output() bookingSelected = new EventEmitter<Booking>()
    @Output() bookingRequestSelected = new EventEmitter<BookingRequest>()
    @ViewChild('bookingRow') bookingRowTemplate: any
    @ViewChild('uncompletedBookingRequestRow') uncompletedBookingRequestTemplate: any
    showBookingDateTime = true
    showBookingTime = false

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['options']) {
            this.showBookingDateTime = this.options?.showBookingDateTime ?? true
            this.showBookingTime = this.options?.showBookingTime ?? false
        }
    }

    getRowTemplate(item: HasBookingStatus) {
        if (this.isItemABooking(item)) {
            return this.bookingRowTemplate
        }
        return this.uncompletedBookingRequestTemplate
    }

    bookingClicked(booking: HasBookingStatus) {
        if (this.isItemABooking(booking)) {
            this.bookingSelected.emit(booking as Booking)
            return
        }
        this.bookingRequestSelected.emit(booking as BookingRequest)
    }

    private isItemABooking(item: HasBookingStatus): item is Booking {
        return (item as Booking).statusEvents !== undefined
    }
}

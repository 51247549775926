import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms'
import { Booking } from '../../../../domain/Booking'
import { BookingService } from '../../../../services/booking.service'
import { Business } from '../../../../domain/Business'
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core'
import { Contact } from '../../../../domain/Contact'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Organisation } from '../../../../domain/Organisation'
import { ToastBuilder } from '../../../../domain/ToastBuilder'
import { ToastService } from '../../../../services/toast.service'
import { ToastType } from '../../../../domain/Toast'
import { Venue } from '../../../../domain/Venue'
import { takeUntil } from 'rxjs'

@Component({
    selector: 'app-add-new-customer-to-booking',
    templateUrl: './add-new-customer-to-booking.component.html',
})
export class AddNewCustomerToBookingComponent implements OnInit, OnDestroy {

    @Input() organisation!: Organisation
    @Input() business!: Business
    @Input() venue!: Venue
    @Input() booking!: Booking
    form!: FormGroup
    private onDestroy = new EventEmitter()

    constructor(
        private bookingService: BookingService,
        private fb: FormBuilder,
        private modal: NgbActiveModal,
        private toastService: ToastService
    ) {}

    ngOnInit() {
        this.form = this.makeForm()
    }

    ngOnDestroy() {
        this.onDestroy.emit()
        this.onDestroy.complete()
    }

    dismiss() {
        this.modal.dismiss()
    }

    addCustomer() {
        const contact = new Contact(
            this.form.value.firstName || null,
            this.form.value.lastName || null,
            this.form.value.emailAddress || null,
            this.form.value.phoneNumber || null
        )
        this.bookingService.createNewCustomerAttachedToBooking(
            this.organisation.id,
            this.business.id,
            this.venue.id,
            this.booking.id,
            contact
        )
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: (booking) => {
                    this.modal.close(booking)
                },
                error: (error) => {
                    console.error(error)
                    if (error.status === 409) {
                        this.toastService.show(
                            new ToastBuilder('Customer with this email address already exists')
                                .withType(ToastType.Danger)
                                .withHeader('Failed to create customer')
                                .build()
                        )
                    }
                },
            })
    }

    private makeForm() {
        return this.fb.group({
            firstName: this.booking.contact.firstName,
            lastName: this.booking.contact.lastName,
            emailAddress: [this.booking.contact.emailAddress, [this.optionalEmailValidator]],
            phoneNumber: this.booking.contact.phoneNumber,
        },
        {
            validators: [this.atLeastOneRequiredValidator],
        })
    }

    private optionalEmailValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value
        if (!value) {
            return null
        }
        return Validators.email(control)
    }

    private atLeastOneRequiredValidator(group: AbstractControl): ValidationErrors | null {
        const controls = (group as FormGroup).controls
        const hasValue = Object.keys(controls).some(key => controls[key].value)
        return hasValue ? null : { atLeastOneRequired: true }
    }
}

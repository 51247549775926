import { Area } from '@app/domain/Area'
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnChanges,
    OnInit,
    Output, SimpleChanges,
} from '@angular/core'
import {
    FloorTableViewModel,
} from '@app/features/shared/components/area-floor/floor-table.view-model'
import { Venue } from '@app/domain/Venue'

@Component({
    selector: 'app-venue-floors',
    templateUrl: './venue-floors.component.html',
    styleUrls: ['./venue-floors.component.sass'],
})
export class VenueFloorsComponent implements OnInit, OnChanges {

    @Input() selectedArea!: Area
    @Input() viewModels!: FloorTableViewModel[]
    @Input() venue!: Venue
    @Input() showTime = false
    now!: Date
    areas!: Area[]
    @Output() areaSelected = new EventEmitter<Area>()

    constructor(
        @Inject(LOCALE_ID) public locale: string
    ) { }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) {
        this.now = this.nowDate()
        if (changes['venue']) {
            this.areas = this.venue.areas
        }
    }

    selectArea(area: Area) {
        this.areaSelected.emit(area)
    }

    canSelectAllInSelectedArea() {
        return this.viewModels.some(vm => !vm.isSelected && !vm.isDisabled && !vm.isBooked)
    }

    selectAllInArea() {
        this.viewModels.forEach(vm => {
            if (!vm.isSelected && !vm.isDisabled && !vm.isBooked) {
                vm.tableClicked()
            }
        })
    }

    private nowDate() {
        const now = new Date()
        return new Date(
            now.toLocaleString(this.locale, {
                timeZone: this.venue.timeZone,
            })
        )
    }
}
